import React from "react";
import classes from "./Intro.module.css";
import image from './Images/performance pressure.png';
import { useMediaQuery } from "@mui/material";

function About() {
    const isNonMobileScreens = useMediaQuery("(min-width:1001px)");
    return (
        <div className={classes.outer} id="introduction">
            <div className={classes.top}>INTRODUCTION</div>
            <div className={classes.wrapper}>
                <div className={classes.rightbarFollowings} style={{width:isNonMobileScreens?"31%":"90%"}}>
                    <span style={{fontSize:"1.5rem", fontWeight:"500"}}>The Transition phase for Youth </span>through countries, cities, schools and colleges thereafter can be quite a task. The social pressures, extra curricular activities, academic pressures, changing world-views, and the competition to top it all makes the journey challenging. When you sign up for the Mindmines BLOC Program we are with you through the ride.
                </div>
                <div className={classes.rightbarFollowings} style={{width:isNonMobileScreens?"33%":"90%"}}>
                    <span style={{fontSize:"1.5rem", fontWeight:"500"}}>One-on-One Support</span> through the years is what is required during such the transitional phase, not knee jerk interventions. We connect with you through our one on one interactions, scientific tests and establish an emotional and academic support system through the years to help you achieve your ambitions and aspirations. A bond that remains even when you move countries or continents.
                </div>
                <div className={classes.rightbarFollowings} style={{width:isNonMobileScreens?"33%":"90%", marginRight:isNonMobileScreens?"1.5rem":""}}>
                    <span style={{fontSize:"1.5rem", fontWeight:"500"}}>Building (BLOCs)</span> Behavioral, Learning, Organizational and Career related aspects through tests widely used across the globe. The results will help you chart out a concrete plan to navigate through life. Our prime focus is to help overcome any potential barriers to Self-development, Settling-In, Learning, Communication or your Well-Being to help chart out a path for yourself. A path that resonates with you.
                </div>
            </div>
            <img src={image} alt='performance presure' style={{ width: "100%", objectFit: "contain", maxHeight: "30rem" }} />
        </div>
    );
}

export default About;