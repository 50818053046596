import React, { useEffect } from 'react'
import classes from './Behaviour.module.css';
import behaviour from './Images/behaviour.jpg';
import B from './BLOC/B';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';

const Behaviour = () => {
    const isNonMobileScreens = useMediaQuery("(min-width:1001px)");
    const { state } = useLocation();
    useEffect(() => {
        if (state && (state.source === 'innerBox1' || state.source === "image")) {
            document.documentElement.scrollTop = 0;
        }
    }, [state]);

    return (
        <div className={classes.body} id="top">
            <div className={classes.top}>
                <div className={classes.text}>
                    <strong>BEHAVIOUR</strong> is a symptom of what we feel inside. Adolescence is a very unsettling time in life, when a child is thrown into a sea of emotions arising out of physical, mental and social changes all at once. There are bound to be fireworks....and How !!During this period of constant flux, parents, teachers and adults can play a pivotal role in the adolescents life, if they learn to understand and interpret their behavior better. Guiding them with patience and providing a non-judgmental space allows adolescents to express themselves, fostering a sense of trust and connection that is crucial for their emotional well-being.
                </div>
                <img src={behaviour} alt='learning' className={classes.img}></img>
            </div>
            <div className={classes.middle}>
                <i className="fa fa-minus" style={{ fontSize: "48px", color: "red", marginLeft: isNonMobileScreens ? "20%" : "", marginRight: "5rem" }}></i>
                {isNonMobileScreens && <div style={{ display: "flex" }}>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.dash}></div>
                    <div className={classes.arrow}></div>
                </div>}
                <i className="fa fa-plus" style={{ fontSize: "48px", color: "green", marginLeft: "8%" }}></i>
            </div>
            <div className={classes.bottom}>
                {isNonMobileScreens && <div className={classes.left}>
                    <h1 className={classes.heading}>Behaviour</h1>
                </div>}
                <div className={classes.right}>
                    <div className={classes.box1}>
                        <p><strong>Anxious:</strong> worried, moody or nervous</p>
                        <p><strong>Insensitive:</strong> unfriendly, bully</p>
                        <p><strong>Careless:</strong>not being careful; rushing into things</p>
                        <p><strong>Impatient:</strong> quickly irritated and easily provoked</p>
                        <p><strong>Lazy:</strong> unwilling to work or showing a lack of effort</p>
                        <p><strong>Rigid:</strong> being unwilling to change one's outlook, belief, or response</p>
                        <p><strong>Undisciplined:</strong> lacking in discipline</p>
                    </div>
                    <div className={classes.box2}>
                        <p><strong>Active:</strong> always busy with something</p>
                        <p><strong>Sensitive:</strong> in touch with feelings</p>
                        <p><strong>Cautious:</strong> being very careful.</p>
                        <p><strong>Conscientious:</strong> taking time to do things right</p>
                        <p><strong>Creative:</strong> someone who can make up things easily or think of new things</p>
                        <p><strong>Curious:</strong> always wanting to know things</p>
                        <p><strong>Organized:</strong> dealing with one's affairs efficiently</p>
                    </div>
                </div>
            </div>
            <B />
        </div>
    );
}

export default Behaviour;