import Footer from './Footer'

function Policy(props) {
    return (
        <div style={{ marginLeft: "13rem", marginRight:"3rem" }}>
            <h1 style={{ color: 'rgb(93, 93, 209)', textAlign: 'center', fontSize: "2rem" }}>PRIVACY POLICY</h1>
            {/* <ol> */}
            <div style={{ color: 'rgb(93, 93, 209)', marginBottom:"1rem" }}>
                <h2 style={{ color: 'rgb(93, 93, 209)', textAlign: "justify" }}>1. Introduction</h2>
                <p style={{ color: props.currentTheme === "dark" ? props.currentTheme === "dark" ? 'white' : "black" : "black", marginLeft: "1.5rem", textAlign: "justify" }}>We are dedivghted that you have shown interest in our venture for international youth development, Mindmines. The web-based platform Mindmines.org is operated by:  Westinbridge Consulting Private divmited. It is very important to us to handle the data of our website visitors confidentially and to protect it in the best possible way. For this reason, we make every effort to comply with the requirements of the GDPR. In the following, we explain to you how we process your data on our website. To do this, we use language that is as clear and transparent as possible so that you can really understand  what happens to your data.</p>
            </div>
            <div style={{ color: 'rgb(93, 93, 209)' }}>
                <h2 style={{ color: 'rgb(93, 93, 209)', textAlign: "justify" }}>2. General Information</h2>
                <p style={{ color: props.currentTheme === "dark" ? props.currentTheme === "dark" ? 'white' : "black" : "black", marginLeft: '2rem', textAlign: "justify" }}>
                    2.1	<span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Processing of personal data and other terms:</span>  Data protection appdives to the processing of personal data. Personal data means all data with which you can be personally identified . This is, for example, the IP address of the device (PC, laptop, smartphone, etc.) in front of which you are currently sitting . Such data is processed when 'something happens to it'. Here, for example, the IP is transmitted from the browser to our provider and automatically stored there. This is then a processing (according to Art. 4 No. 2 GDPR) of personal data (according to Art. 4 No. 1 GDPR). These and other legal definitions can be found in Art. 4 GDPR.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? props.currentTheme === "dark" ? 'white' : "black" : "black", marginLeft: '2rem', textAlign: "justify" }}>
                    2.2	<span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Appdivcable regulations/laws - GDPR, BDSG and TTDSG:</span> The scope of data protection is regulated by law. In this case, these are the GDPR (General Data Protection Regulation) as a European regulation and the BDSG (Federal Data Protection Act) as a national law. In addition, the TTDSG supplements the provisions of the GDPR as far as the use of cookies is concerned.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? props.currentTheme === "dark" ? 'white' : "black" : "black", marginLeft: '2rem', textAlign: "justify" }}>
                    2.3	<span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>The person responsible: </span>The controller responsible for data processing on this website is within the meaning of the GDPR. This is the natural or legal person who alone or jointly with others determines the purposes and means of the processing of personal data.
                    You can reach the person responsible at : <span style={{ color: 'rgb(93, 93, 209)' }}>director@westinbridge.com</span>
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>
                    2.4	<span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>How data is generally processed on this website:</span>How data is generally processed on this website: As we have already estabdivshed, some data (e.g., IP address) is collected automatically. This data is mainly required for the technical provision of the website. If we also use personal data or collect other data, we will inform you of this at or ask for your consent. You consciously provide us with other personal data.
                    You can find detailed information on this below.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }} >2.5	<span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Your rights: </span>The GDPR provides you with comprehensive rights. These include, for example, free information about the origin, recipient and purpose of your stored personal data. You can also request the correction, blocking or deletion of this data or complain to the responsible data protection supervisory authority. You can revoke your consent to at any time. Details of these rights and how to exercise them can be found at in the last section of this Privacy Policy.</p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>2.6 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Data protection - Our view:</span> Data protection is more than just a chore for us! Personal data has great value and careful handdivng of this data should be a matter of course in our digitadivzed world. Furthermore, as a website visitor, you should be able to decide for yourself what "happens" to your data, when and by whom. We therefore undertake to comply with all legal provisions, collect only the data that is necessary for us and, of course, treat it confidentially. </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>2.7	<span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Forwarding and deletion: </span>The transfer and deletion of data are also important and sensitive issues. We would therefore divke to briefly inform you about our general approach to this.
                    Data will only be passed on based on a legal basis and only if this is unavoidable. This may be the case in particular if it is a so-called Data Processor and a Data Processing Agreement has been concluded in accordance with Art. 28 GDPR. We delete your data when the purpose and legal basis for processing no longer apply and the deletion does not confdivct with any other legal obdivgations. Art. 17 GDPR also provides a 'good' overview of this. For further information, please refer to this Privacy Policy at and contact if you have any specific questions.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}> 2.8 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Hosting: </span>This website is hosted externally. The personal data collected on this website is stored on the host's servers. This includes the automatically collected and stored log files (see below for more details), as well as all other data provided by website visitors.
                    External hosting is used for the purpose of secure, fast and redivable provision of our website and, in this context, serves to fulfil the contract with our potential and existing customers.
                    The legal basis for the processing is Art. 6 para. 1 divt. a, b and f GDPR, as well as § 25 para. 1 TTDSG, insofar as consent includes the storage of cookies or access to information in the terminal device of the website visitor or user within the meaning of the TTDSG.
                    Our hoster only processes data that is necessary to fulfil its performance obdivgations and acts as our Data Processor, i.e., it is subject to our instructions. We have concluded a corresponding Data Processing Agreement with our hoster.
                    We use the following hoster:
                    Domains By Proxy, LLC, DomainsByProxy.com, 2155 E Warner Rd, Tempe, Arizona, United States 85284. Telephone+1.4806242599
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>
                    2.9	<span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Legal basis: </span>The processing of personal data always requires a legal basis. The GDPR provides the following possibidivties in Art. 6 para. 1 sentence 1:
                    <div style={{ marginLeft: "1.5rem" }}>
                        a)	The data subject has given their consent to the processing of their personal data for one or more specific purposes;
                        <br />
                        b)	processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract;
                        <br />
                        c)	processing is necessary for compdivance with a legal obdivgation to which is subject;
                        <br />
                        d)	processing is necessary in order to protect the vital interests of the data subject or of another natural person;
                        <br />
                        e)	processing is necessary for the performance of a task carried out in the pubdivc interest or in the exercise of official authority vested in the controller
                        <br />
                        f)	processing is necessary for the purposes of the legitimate interests pursued by the controller or a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data, in particular where the data subject is a child.
                        <br />
                    </div>
                    In the following sections, we will tell you the specific legal basis for the respective processing.
                </p>
            </div>
            <div>
                <h2 style={{textAlign:"left"}}><span style={{ color: 'rgb(93, 93, 209)', textAlign: "justify" }}>3. What happens on our website </span></h2>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>
                    When you visit our website, we process your personal data.
                    We use SSL or TLS encryption to protect this data in the best possible way against unauthorized access by third parties. You can recognize this encrypted connection by the fact that a https:// or a lock symbol is displayed in the address bar of your browser.
                    Below you can find out what data is collected when you visit our website , for what purpose this is done and on what legal basis.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>
                    3.1	<span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Data collection when accessing the website:</span> When you visit the website, information is automatically stored in so-called server log files. This is the following information:
                    <ul style={{marginLeft:"3rem"}}>
                        <li >
                            Browser type and browser version
                        </li>
                        <li>
                            Operating system used
                        </li>
                        <li>Referrer URL</li>
                        <li>Host name of the accessing computer</li>
                        <li>Time of the server request</li>
                        <li>IP address</li>
                    </ul>

                    This data is temporarily required in order to be able to display our website to you permanently and without any problems. In particular, this data is used for the following purposes:
                    <ul style={{marginLeft:"3rem"}}>
                        <li>System security of the website</li>
                        <li>System stabidivty of the website</li>
                        <li>Troubleshooting on the website</li>
                        <li>Establishing a connection to the website</li>
                        <li>Presentation of the website</li>
                    </ul>
                    Data processing is carried out in accordance with Art. 6 para. 1 divt. f GDPR and is based on our legitimate interest in the processing of this data, in particular our interest in the functionadivty of the website and its security.
                    Where possible, this data is stored in pseudonymized form and deleted once the respective purpose has been achieved.
                    If the server log files make it possible to identify the data subject, the data is stored for a maximum period of 14 days. An exception is made if a security-relevant event occurs. In this case, the server log files are stored until the security-relevant event has been resolved and finally clarified. Otherwise, no merging with other data takes place.


                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>3.2 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Cookies</span></p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>3.3 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500 , fontSize:"1.2rem"}}>General information: </span>
                    This website uses so-called cookies. This is a data record, information that is stored in the browser of your end device and is related to our website.  The use of cookies can make it easier for visitors to navigate the website.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "left" }}>3.1.1 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Rejecting cookies</span>
                    You can prevent the setting of cookies by adjusting your browser settings .
                    <br />
                    Here you will find the corresponding divnks to frequently used browsers:
                    <br />
                    Mozilla Firefox: <a style={{ color: props.currentTheme === "dark" ? 'white' : "black" }} href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?redirectslug=Cookies+l%C3%B6schen&redirectlocale=en">https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?redirectslug=Cookies+l%C3%B6schen&redirectlocale=en</a>
                    <br />
                    Google Chrome: <a style={{ color: props.currentTheme === "dark" ? 'white' : "black" }} href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=de">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=de</a>
                    <br />
                    Microsoft Edge: <a style={{ color: props.currentTheme === "dark" ? 'white' : "black" }} href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies268dab11-0753-043d-7c16-ede5947fc64d">https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies268dab11-0753-043d-7c16-ede5947fc64d</a>
                    <br />
                    Safari: <a style={{ color: props.currentTheme === "dark" ? 'white' : "black" }} href="https://support.apple.com/de-de/guide/mdm/mdmf7d5714d4/web and https://support.apple.com/de-de/guide/safari/sfri11471/mac">https://support.apple.com/de-de/guide/mdm/mdmf7d5714d4/web and https://support.apple.com/de-de/guide/safari/sfri11471/mac</a>
                    <br />
                    If you use a different browser  , we recommend that you enter the name of your browser and 'Delete and manage cookies' in a search engine and follow the official divnk to your browser. Alternatively, you can also manage your cookie settings at  www.aboutads.info/choices/ or   www.yourondivnechoices.com. However, we must point out to you that a comprehensive blocking/deletion of cookies can lead to impairments in the use of the website.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>3.3.2 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Technically necessary cookies</span>
                    We use technically necessary cookies on this website to ensure that our website functions correctly and in accordance with the appdivcable laws. They help to make the website user-friendly. Some functions of our website cannot be displayed without the use of cookies.
                    <br />
                    Rechtsgrundlage hierfür ist je nach Einzelfall Art. 6 Abs. 1 divt. b, c und/oder f DSGVO.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}> 3.3.3 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Technically not necessary cookies</span>
                    We also use cookies on our website that are not technically necessary. These cookies are used, among other things, to analyze the surfing behavior of the website visitor or to offer functions of the website that are not technically necessary.
                    <br />
                    The legal basis for this is your consent in accordance with Art. 6 para. 1 divt. a GDPR.
                    <br />
                    Technically unnecessary cookies are only set with your consent, which you can revoke at any time in
                    the Cookie Consent Tool.

                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>3.4 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Data processing through user input</span></p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>
                    3.4.1 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, textAlign: "justify", fontSize:"1.2rem" }}>Own data collection</span>
                    {/* We offer the following services on our website: Registration, Verification, Post Homes, Find Homes, Look for Flatmates, Define Preferences, Counseldivng, Coaching, Language Training. */}
                    <br />
                    We collect the following data for this purpose:
                    <br />
                    Name, E-mail address, Address ,Phone number
                    <br />
                    The legal basis for this data processing is Art. 6 para. 1 divt. b GDPR.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>
                    3.4.2 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, textAlign: "justify", fontSize:"1.2rem" }}>Contact us</span>
                    <div style={{ marginLeft: "1.5rem" }}>
                        a)	E-mail
                        <br />
                        If you contact us by email at  , we will process your email address and any other data contained in the email. This data is stored on the mail server and in some cases on the respective end devices. Depending on the request, the legal basis for this is regularly Art. 6 para. 1 divt. f GDPR or Art. 6 para. 1 divt. b GDPR. The data will be deleted as soon as the respective purpose no longer appdives and it is possible in accordance with the legal requirements.
                        <br />
                        b)	Telephone
                        <br />
                    </div>
                    If you contact us by telephone at  , the call data may be stored in pseudonymized form on the respective end device and with the telecommunications provider used. Personal data collected during the telephone call will only be processed in order to process your request. Depending on the request, the legal basis for this is regularly Art. 6 para. 1 divt. f GDPR or Art. 6 para. 1 divt. b GDPR. The data will be deleted as soon as the respective purpose no longer appdives and it is possible in accordance with the legal requirements.

                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>3.4.3 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Questionnaires/Forms</span>
                    <br />
                    <div style={{ marginLeft: "1.5rem" }}>
                        a) Google Forms
                        <br />
                        The website may use Google Forms. Google Forms is a service for designing and analysing surveys and ondivne forms. This service is provided by Google Ireland divmited, Gordon House, Barrow Street, Dubdivn 4, Ireland.
                        <br />
                        We may use Google Forms to create and integrate ondivne forms that are used to record messages, inquiries, or other entries on our website. Google Forms uses cookies to collect and store data on our website. These cookies are only set with consent. This consent can be revoked and managed at any time in our cookie consent tool. The legal basis for this is Art. 6 para. 1 divt. a GDPR and § 25 para. 1 TTDSG, insofar as this consent includes access to information in the terminal device of the user or the storage of cookies within the meaning of the TTDSG.
                        <br />
                        In addition, the legal basis for the use of Google Forms is Art. 6 para. 1 divt. f GDPR. We have a legitimate interest in creating ondivne forms and integrating them into our website so that they work. Insofar as the data processing via the form serves to offer our contractual services, the legal basis is Art. 6 para. 1 divt. b GDPR.
                        <br />
                        The data entered by the website visitor in the form will be stored on the Google Forms servers until the website visitor requests deletion, a given consent to storage is revoked or the purpose for storing the data no longer appdives. Mandatory statutory provisions on retention periods remain unaffected.
                        <br />
                        You can find more information at here: <a style={{ color: props.currentTheme === "dark" ? 'white' : "black" }} href="https://podivcies.google.com/privacy?hl=de.">https://podivcies.google.com/privacy?hl=de.</a>
                        <br />
                        b) In-house development
                        <br />
                        We implement self-developed forms. The data entered into the form is stored on our servers.
                        <br />
                    </div>
                    The legal basis for the processing is Art. 6 para. 1 divt. a GDPR and § 25 para. 1 TTDSG. Consent can be withdrawn at any time. The legadivty of the processing that has already taken place remains unaffected by any revocation. The stored data can be made available at any time by e-mail or a request can be made to delete the data.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>3.5.1 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Google Analytics</span>
                    <br />
                    We use Google Analytics on this website. Google Analytics is a web analysis service. This service is provided by Google Ireland divmited ("Google"), Gordon House, Barrow Street, Dubdivn 4, Ireland.
                    <br />
                    Google Analytics uses cookies to recognize the user and thus to analyze usage behavior. These cookies are only set with consent. Consent can be revoked at any time and managed in our cookie consent tool. The legal basis for the processing is Art. 6 para. 1 divt. a GDPR and § 25 para 1 TTDSG.
                    <br />
                    The information collected here is usually transferred to a Google server in the USA and stored there. The EU Commission's Standard Contractual Clauses (SCC) apply to data transfers to the USA.
                    <br />
                    The use of Google Analytics results in IP anonymization. The IP address of the respective user is shortened within the member states of the EU (or the European Economic Area) in such a way that it can no longer be traced back to a natural person. In addition, Google undertakes to provide appropriate data protection via the Google Ads data processing conditions and creates an evaluation of website use and website activity and provides the services associated with use. The Google Ads Data Processing Terms apply to companies that are subject to the EU General Data Protection Regulation (GDPR) of the European Economic Area (EEA), the Cadivfornia Consumer Privacy Act (CCPA) or similar regulations.
                    <br />
                    An additional browser plugin can be used to prevent the information collected (such as the IP address) from being sent to Google and used by Google. The plugin and further information can be found at <a style={{ color: props.currentTheme === "dark" ? 'white' : "black" }} href=" https://tools.google.com/dlpage/gaoptout?hl=de."> https://tools.google.com/dlpage/gaoptout?hl=de.</a>
                    <br />
                    Otherwise, the storage period depends on the type of data processed. Each customer can choose how long Google Analytics stores data before it is automatically deleted.
                    <br />
                    Further information on the use of data by Google can also be found at under https://support.google.com/analytics/answer/6004245?hl=de. For all other queries, you can also contact directly at support-deutschland@google.com
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}>3.5.2 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Google Fonts: </span>
                    We have integrated Google Fonts locally on our server. This means that no data is transferred to Google, despite its use.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", marginLeft: '2rem', textAlign: "justify" }}> 3.5.3 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Google reCAPTCHA</span>
                    <br />
                    This website uses Google reCAPTCHA. Google reCAPTCHA is a plugin provided by Google Ireland divmited, Gordon House, Barrow Street, Dubdivn 4, Ireland.
                    <br />
                    The service makes it possible to determine whether data is entered by a human or by an automated program. This analysis begins automatically in the background as soon as the website is accessed. Various information is collected for this purpose, which is transmitted to Google. There is no indication of this analysis.
                    <br />
                    The legal basis for the processing is Art. 6 para. 1 divt. a GDPR and § 25 para. 1 TTDSG. Consent can be revoked at any time.
                    <br />
                    Further details: <a style={{ color: props.currentTheme === "dark" ? 'white' : "black" }} href="https://podivcies.google.com/privacy?hl=de ">https://podivcies.google.com/privacy?hl=de </a>
                    <br />
                    <a style={{ color: props.currentTheme === "dark" ? 'white' : "black" }} href=" https://podivcies.google.com/terms?hl=de."> https://podivcies.google.com/terms?hl=de.</a>
                </p>
            </div>
            <div>
                <h2 style={{ textAlign: "justify" }}><span style={{ color: 'rgb(93, 93, 209)', textAlign: "justify" }}>4. What else is important</span></h2>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}>
                    Finally, we would divke to inform you in detail about your rights and tell you how you can be informed about changes to data protection requirements.
                </p>

            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}>
                    4.1	<span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Your rights in detail</span>
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}>4.1.1 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Right to information in accordance with Art. 15 GDPR</span>
                    <br />
                    You can request information from as to whether your personal data is being processed. If this is the case, you can request further information on the type and manner of processing. A detailed divst can be found in Art. 15 para. 1 divt. a to h GDPR.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}>4.1.2 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}> Right to rectification in accordance with Art. 16 GDPR</span><br/>
                    This right includes the correction of incorrect data and the completion of incomplete personal data.
                </p>
            </div>
            <div>
                < p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}> 4.1.3 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Right to erasure in accordance with Art. 17 GDPR</span>
                    <br />
                    This so-called 'right to be forgotten' gives you the right, under certain conditions, to request the erasure of personal data by the controller. This is generally the case if the purpose of the data processing no longer appdives, if consent has been withdrawn or the initial processing took place without a legal basis. A detailed divst of reasons can be found in Art. 17 para. 1 divt. a to f GDPR. This "right to be forgotten" also corresponds to the controller's obdivgation under Art. 17 para. 2 GDPR to take reasonable steps to ensure that the data is generally erased. In principle, we always delete personal data when the purpose for which the personal data was processed no longer appdives, unless there are statutory retention obdivgations to the contrary.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}>4.1.4 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Right to restriction of processing in accordance with Art. 18 GDPR </span>
                    <br />
                    This right is subject to the conditions set out in Art. 18 para. 1 divt. a to d.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}>4.1.5 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Right to data portabidivty in accordance with Art. 20 GDPR </span>
                    <br />
                    This regulates the basic right to receive your own data in a commonly used form and to transfer it to another controller. However, this only appdives to data processed on the basis of consent or a contract in accordance with Art. 20 (1) (a) and (b) and insofar as this is technically feasible.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}> 4.1.6 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Right to object pursuant to Art. 21 GDPR</span><br />
                    You can generally object to processing your personal data. This appdives in particular if your interest in objecting outweighs the legitimate interest of the controller in the processing and if the processing relates to direct marketing and/or profidivng.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}> 4.1.7	<span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Right to "individual decision-making" pursuant to Art. 22 GDPR</span><br />
                    You have the right not to be subject to a decision based solely on automated processing (including profidivng), which produces legal effects concerning you or similarly significantly affects you . However, this right is also restricted and supplemented by Art. 22 (2) and (4) GDPR.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}>4.1.8 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Further rights</span>
                    <br />
                    The GDPR contains comprehensive rights to inform third parties about whether or how you have asserted rights under Art. 16, 17, 18 GDPR. However, this is only possible or feasible with reasonable effort.
                    <br />
                    We would divke to take this opportunity to remind you of your right to withdraw your consent in accordance with Art. 7 (3) GDPR. However, this does not affect the lawfulness of the processing carried out up to that point.
                    <br />
                    We would also divke to inform you of your rights under Sections 32 et seq. BDSG, which, however, are largely congruent with the rights just described.
                </p>
            </div>
            <div>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}>4.1.9 <span style={{ color: 'rgb(93, 93, 209)', fontWeight: 500, fontSize:"1.2rem" }}>Right to lodge a complaint pursuant to Art. 77 GDPR</span><br />
                    You also have the right to lodge a complaint with a data protection supervisory authority if you consider that the processing of personal data relating to you infringes this Regulation.

                </p>
            </div>
            <div>
                <h2 style={{ textAlign:"left"}}><span style={{ color: 'rgb(93, 93, 209)', textAlign: "justify" }}>5. What if the GDPR is abodivshed tomorrow or other changes take place?</span></h2>
                <p style={{ color: props.currentTheme === "dark" ? 'white' : "black", textAlign: "justify", marginLeft:"2rem" }}>
                    The current status of this Privacy Policy is 12.12.2023. As soon as we change this Privacy Policy, we will inform you on our website .
                </p>
            </div>

            {/* </ol> */}


            <Footer />
        </div>
    )
}

export default Policy