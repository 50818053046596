import React, { useEffect } from 'react'
import classes from './Career.module.css';
import career from './Images/career.jpg';
import C from './BLOC/C';
import { useLocation } from 'react-router-dom';

const Career = () => {
  const { state } = useLocation();
  useEffect(() => {
    if (state && (state.source === 'innerBox1' || state.source === "image")) {
      document.documentElement.scrollTop = 0;
    }
  }, [state]);

  return (
    <div className={classes.body}>
      <div className={classes.top}>
        <div className={classes.text}>
          Our <strong>CAREERS</strong> are rarely a function of our choices. We all understand the significance of career choices. Surprisingly, most of us find ourselves stuck in jobs we don't really want to do or enjoy doing. Don't let this happen to your child. With us, students can deftly navigate diverse career options and choose the one that is just right for thema and aligns seamlessly with their aspirations. Our personalized guidance fosters a proactive approach, helping students make informed decisions towards a purposeful and rewarding professional life.
        </div>
        <img src={career} alt='learning' className={classes.img}></img>
      </div>
      <div className={classes.bottom}>
        <div className={classes.left}>
          <h1 className={`${classes.heading} ${classes.rotate}`}>Career</h1>
        </div>
        <div className={classes.right}>
          <div className={classes.box1}>
            <h3>CHOOSING RIGHT...</h3>
            <p className={classes.para1}>At senior school, opting for the right courses and taking informed decisions about the most suitable career options becomes critical. Our mission is to empower each one to take a balanced decision about the kind of career most suitable to your natural interests and abilities.</p>
            <p className={classes.para2}>Getting into a study course that does not suit your natural interests and abilities can come the way of future prospects. This is why you must know what your natural aptitudes are a how best to relate them to potential academic and career choices.</p>
          </div>
          <div className={classes.box2}>
            <h3>TRUE POTENTIAL...</h3>
            <p className={classes.para3}>Educational psychologists and educationists have been talking about different learning and thinking styles for many years now.</p>
            <p className={classes.para4}>There are scientific tests that objectively determine a student's natural abilities and cognitive styles. A career counseling based on these findings has the backing of scientific data to guide you towards a life and career you are best suited for.</p>
            <p className={classes.para5}>We will take you through these tests so that you know where your optimal potential lies. The test results will also help your parents have realistic expectations from you.</p>
          </div>
        </div>
      </div>
      <C />
    </div>
  );
}

export default Career;