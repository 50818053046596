import classes from "./Footer.module.css";
import { useNavigate } from "react-router-dom";

const links = [
    {
        title: "About",
        data: ["About", "Privacy Policy"],
        click: ["#introduction", "/privacypolicy"],
    },
    {
        title: "Contact",
        data: ["Press", "Support"],
        click: ["#contact", "#contact"],
    },
    {
        title: "Social",
        data: ["Mail"],
        click: ["mailto:support@mindmines.org",],
    },
];

// const socialLink = [
//   <BsFacebook />,
//   <BsTwitter />,
//   <BsInstagram />,
//   <BsLinkedin />,
// ];

const Footer = props => {
    const navigate = useNavigate();
    const upperClasses = `${classes.upper}`;
    const lowerClasses = `${classes.lower} `;
    const linkClasses = `${classes.link} `;

    return (
        <footer className={classes.footer}>
            <div style={{ borderTop: "1px solid black", width: "95%", marginLeft: "2rem" }}></div>
            <div className={upperClasses}>
                <div className={classes["brand-container"]} >
                    <div style={{ display: "flex", marginLeft:"3.4rem" }}>
                        <h1 style={{ fontSize: "1.8rem" }}>Bridging Cultures | Empowering Youth | Shaping Futures | Unlocking Potential</h1>
                    </div>
                </div>
                <div className={classes.links}>
                    {links.map(({ title, data }, index) => {
                        return (
                            <div className={linkClasses} key={index}>
                                <h4 style={{ textAlign: "left" }}>{title}</h4>
                                <ul style={{ paddingLeft: 0, cursor: "pointer", textAlign: "left" }}>
                                    {data.map((link, index2) => (
                                        <li onClick={() => navigate('/')} key={index2} style={{ width: "max-content" }}>
                                            <a href={links[index].click[index2]} className={classes.alink} style={{ color: "black" }}>{link}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={lowerClasses}>
                <span style={{ color: props.currentTheme === "dark" ? "#ffffff" : "black" }}>&copy; Copyright 2023 Mindmines</span>
                <span style={{ color: props.currentTheme === "dark" ? "#ffffff" : "black" }}>Launching December 2023</span>
            </div>
        </footer>
    );
}

export default Footer;