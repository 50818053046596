import './App.css';
import Behaviour from './components/Behaviour';
import Learning from './components/Learning';
import HomePage from './components/HomePage';
import LeftNavbar from './components/LeftNavbar';
import Organization from './components/Organization';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Career from './components/Career';
import { useMediaQuery } from '@mui/material';
import Policy from './components/PrivacyPolicy';

function App() {
  const isNonMobileScreens = useMediaQuery("(min-width:1001px)");
  return (
    <div className="App">
      <div className='maincontainer'>
        <BrowserRouter >
          {isNonMobileScreens && <LeftNavbar />}
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/Behaviour' element={<Behaviour />} />
            <Route path='/Learning' element={<Learning />} />
            <Route path='/Organization' element={<Organization />} />
            <Route path='/Career' element={<Career />} />
            <Route path='/privacypolicy' element={<Policy />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
