import React from 'react';
import classes from './LeftNavbar.module.css';
import logo from './Images/logo.jpeg';
import block from './Images/block.jpeg';
// import process from './Images/process.jpeg';
import about from './Images/about.jpeg';
import intro from './Images/Intro.png';
import contact from './Images/contactus.jpeg'
import { useNavigate } from 'react-router-dom';

const LeftNavbar = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <a href='#home' className={classes.logo}><img src={logo} alt='logo' onClick={() => navigate('/')} /></a>
      <a href='#introduction'><img src={intro} alt='about' style={{ objectFit: "contain" }} onClick={() => navigate('/')} className={classes.img} /></a>
      <a href='#bloc'><img src={block} alt='block' className={classes.img} onClick={() => navigate('/')} /></a>
      {/* <a href='#process'><img src={process} alt='process' className={classes.img} onClick={() => navigate('/')} /></a> */}
      <a href='#team'><img src={about} alt='home' className={classes.img} onClick={() => navigate('/')} /></a>
      <a href='#contact'><img style={{ objectFit: "contain" }} src={contact} alt='process' onClick={() => navigate('/')} className={classes.img} /></a>
    </div>
  );
}

export default LeftNavbar;