import React from 'react';
import classes from './HomePage.module.css';
import block from './Images/block2.jpeg';
import bgimg from './Images/temp.jpg';
import Intro from './Intro';
import Bloc from './Bloc';
// import Process from './Process';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import BuildingBloc from './BuildingBloc';


function HomePage() {
    return (
        <div className={classes.main} id='home'>
            <div className={classes.container}>
                <img className={classes.bgimg} src={bgimg} alt="bg" />
                <div className={classes.box}>
                    <h2 className={classes.heading1}>BREAK THE</h2>
                    <img src={block} alt='block' className={classes.blockimg} />
                    <h2 className={classes.heading2}>STAY THE COURSE</h2>
                </div>
                <a href='#introduction'><button className={classes.btn} ><span>&#60;</span> LEARN MORE</button></a>
            </div>
            <Intro />
            <Bloc />
            {/* <Process /> */}
            <BuildingBloc />
            <About />
            <Contact />
            <Footer />
        </div>
    )
}

export default HomePage