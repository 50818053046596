import img1 from "./Images/anupama.png";
import img2 from "./Images/dhiren.png";
import img3 from "./Images/rekha.png";
import classes from "./About.module.css";
import { MdOutgoingMail } from "react-icons/md";

function About() {
  return (
    <div className={classes.container} id="team">
      <div className={classes.top}>OUR TEAM</div>
      <div className={classes.boxes}>
        <div className={classes.box}>
          <img
            style={{ height: "12rem", width: "10rem" }}
            src={img3}
            alt="first"
          />
          <p className={classes.name}>Dr. Rekha Singh</p>
          <div className={classes.social}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i style={{ cursor: "pointer", fontSize: "1.7rem" }}>
                <a
                  href="mailto:director@westinbridge.com"
                  className={classes.email}
                >
                  <MdOutgoingMail />
                </a>
              </i>
              <p
                style={{
                  fontSize: "0.8rem",
                  marginTop: "0.4rem",
                  marginLeft: "0.2rem",
                }}
              >
                director@westinbridge.com
              </p>
            </div>
            <i
              class="fa fa-linkedin"
              style={{ marginRight: "1.5rem", cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/rekha---singh/",
                  "_blank"
                )
              }
            ></i>
          </div>
        </div>

        <div className={classes.box}>
          <img
            style={{ height: "12rem", width: "10rem" }}
            src={img2}
            alt="first"
          />
          <p className={classes.name}>Mr. Dhiren Singh</p>
          <div className={classes.social}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i style={{ cursor: "pointer", fontSize: "1.7rem" }}>
                <a
                  href="mailto:director@westinbridge.com"
                  className={classes.email}
                >
                  <MdOutgoingMail />
                </a>
              </i>
              <p
                style={{
                  fontSize: "0.8rem",
                  marginTop: "0.4rem",
                  marginLeft: "0.2rem",
                }}
              >
                director@westinbridge.com
              </p>
            </div>
            <i
              class="fa fa-linkedin"
              style={{ marginRight: "1.5rem", cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/dhiren-singh-6b8400b/",
                  "_blank"
                )
              }
            ></i>
          </div>
        </div>

        <div className={classes.box}>
          <img
            style={{ height: "12rem", width: "10rem" }}
            src={img1}
            alt="first"
          />
          <p className={classes.name}>Dr. Anupama Verma</p>
          <div className={classes.social}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i style={{ cursor: "pointer", fontSize: "1.7rem" }}>
                <a
                  href="mailto:support@mindmines.org"
                  className={classes.email}
                >
                  <MdOutgoingMail />
                </a>
              </i>
              <p
                style={{
                  fontSize: "0.8rem",
                  marginTop: "0.4rem",
                  marginLeft: "0.2rem",
                }}
              >
                support@mindmines.org
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
