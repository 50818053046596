import React from "react";
import classes from "./B.module.css";

function B() {
    return (
        <div className={classes.outer}>
            <div className={classes.wrapper}>
                <div className={classes.inner1}>B</div>
                <div className={classes.inner2}>Assessing Behavioral Blocks</div>
            </div>

            <div className={classes.inner}>
                The tests identify the inherent personality types of individuals and how
                they assist them in learning the art of influencing people. It reveals
                various hereditary strengths and weaknesses that aid better
                introspection and timely action.
                <br />
                <br />
                The tests also map an individuals’ response to environment by
                identifying intrinsic behavioural preferences, communication patterns
                and traits; and help flex the response to suit the environment.
                <br />
                <br />
                The Behavioral tests will help you understand these deeply embedded
                habits and mindsets. You will be able to identify exactly what you need
                to change: <br />
                &#x2022; For more effective communication
                <br />
                &#x2022; For improved interpersonal relationships
                <br />
                &#x2022; For efficient information processing
                <br />
                &#x2022; For accurate  understanding of what is urgent

            </div>
        </div>
    );
}

export default B;
