import React, { useEffect } from 'react'
import classes from './Learning.module.css';
import learning from './Images/learning.jpg';
import L from './BLOC/L';
import { useLocation } from 'react-router-dom';

const Learning = () => {
  const { state } = useLocation();
  useEffect(() => {
    if (state && (state.source === 'innerBox1' || state.source === "image")) {
      document.documentElement.scrollTop = 0;
    }
  }, [state]);

  return (
    <div className={classes.body}>
      <div className={classes.top}>
        <div className={classes.text} style={{ border: "none" }}>
          <strong>LEARNING</strong> is a lifelong process that enriches our life and empowers us to lead healthy and productive lives. A dynamic process of receiving, organizing and interpreting information through observation, reflection or participation. Learning is most successful when the mode of teaching corresponds to a student's natural ability and interest. Different kids learn differently. And can absorb the most when information is fed to them in a form they can digest better. Even areas that are challenging to some, can be made easier through the right learning techniques.
        </div>
        <img src={learning} alt='learning' className={classes.img}></img>
      </div>
      <div className={classes.bottom}>
        <div className={classes.left}>
          <h1 className={classes.rotateit}>Learning</h1>
        </div>
        <div className={classes.right}>
          <div className={classes.rotate}>
            <div className={classes.learn}><p className={classes.rtext}>LEARNING<br /> TO KNOW</p></div>
            <div className={classes.learn}><p className={classes.rtext}>LEARNING<br />  TO DO</p></div>
            <div className={classes.learn}><p className={classes.rtext}>LEARNING<br />  TO STIMULI</p></div>
            <div className={classes.learn}><p className={classes.rtext}>PROCESSING<br />  STIMULI</p></div>
          </div>
          <div className={classes.box}>
            <div className={classes.text}>Learning runs through a cycle of concrete experience, reflective observation, abstract conceptualization and active experimentation: David Kolb</div>
            <div className={classes.text}>Operant conditioning occurs when a response is strengthened or extinguished through reinforcement or punishment of behavior : B. F. Skinner</div>
            <div className={classes.text}>Observational learning occurs through observing the behaviors of others and imitating those behaviors, even if there is no reinforcement : Albert Bandura</div>
          </div>
        </div>
      </div>
      <L />
    </div>
  )
}

export default Learning