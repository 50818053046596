import React from "react";
import classes from "./O.module.css";

function O() {
    return (
        <div className={classes.outer}>
            <div className={classes.wrapper}>
                <div className={classes.inner1}>O</div>
                <div className={classes.inner2}>
                    <p>Gauging Organizational Effectiveness</p>
                </div>
            </div>

            <div className={classes.inner}>
                Organize yourself…. so there’s time to focus on things that give JOY,
                PEACE & HAPPINESS.
                <br />
                <br />
                A high school student is already stressed due to schoolwork, sports,
                after-school activities with generous doses of social media and various
                distraction throw in.
                <br />
                <br />
                Stress gets compounded by procrastination, poor time management, and
                organization in general.
                <br />
                <br />
                Through the CONCOURSE program, we help you plan yourself better, not
                just for the day, but for the next few years. So you get a renewed sense
                of direction and purpose. And are able to chart your life and career
                path better
            </div>
        </div>
    );
}

export default O;
