import React, { useState } from 'react';
import classes from './Contact.module.css';
import contact from './Images/contact.png'
import { TextField, Button, Box, useMediaQuery } from "@mui/material";
import axios from 'axios';

function Contact() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const isNonMobileScreens = useMediaQuery("(min-width:1001px)");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // eslint-disable-next-line
            const response = await axios.post(
                'https://api.sendinblue.com/v3/smtp/email',
                {
                    sender: {
                        name: name,
                        email: email
                    },
                    to: [{
                        email: 'support@mindmines.org',
                        name: 'Support Team'
                    }],
                    subject: subject,
                    htmlContent: `<p>Name: ${name}</p><p>Email: ${email}</p><p>Subject: ${subject}</p><p>Message: ${message}</p>`,
                },
                {
                    headers: {
                        'api-key': process.env.REACT_APP_API_KEY,
                        'content-type': 'application/json'
                    }
                }
            );

            window.alert("Email sent successfully");
        } catch (error) {
            console.error('Error sending email:', error);
            window.alert("Error sending email");
        }
        setEmail("");
        setMessage("");
        setName("");
        setSubject("");
    };
    return (
        <div className={classes.container} id='contact'>
            <div className={classes.top}>CONTACT US</div>
            <div className={classes.main}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: isNonMobileScreens ? "45%" : "80%"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            maxWidth: 600,
                            mx: "auto",
                            p: 2,
                            borderRadius: "12px",
                        }}
                    >
                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                margin="normal"
                                required
                                size='medium'
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                margin="normal"
                                required
                                type="email"
                                size='medium'
                            />
                            <TextField
                                fullWidth
                                label="Subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                margin="normal"
                                required
                                size='medium'
                            />
                            <TextField
                                fullWidth
                                label="Message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                margin="normal"
                                required
                                multiline
                                rows={4}
                            />
                            <Button
                                fullWidth
                                type="submit"
                                sx={{
                                    mt: 2,
                                    backgroundColor: "#6a7e96",
                                    pt: 2,
                                    pb: 2,
                                    color: "#fff",
                                    "&:hover": {
                                        backgroundColor: "#44546a",
                                    },
                                }}
                            >
                                Submit
                            </Button>
                        </form>
                    </Box>
                </Box>
                <img src={contact} width="45%" alt='contact' />
            </div>
        </div>
    )
}

export default Contact