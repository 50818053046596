import React, { useEffect } from 'react'
import classes from './Organization.module.css';
import organization from './Images/organization.jpg';
import O from './BLOC/O';
import { useLocation } from 'react-router-dom';

const Organization = () => {
  const { state } = useLocation();
  useEffect(() => {
    if (state && (state.source === 'innerBox1' || state.source === "image")) {
      document.documentElement.scrollTop = 0;
    }
  }, [state]);

  return (
    <div className={classes.body}>
      <div className={classes.top}>
        <div className={classes.text} style={{ border: "none" }}>
          <strong>The ability to ORGANIZE</strong> oneself in a demanding environment is half the battle won. A little effort at organizing goes a big way in completing tasks on time, reducing stress, and increasing productivity. It clears the mind so you can pursue your interests or hobbies without compromising on your work; it even gives you the added flexibility of squeezing in unplanned activities too. Effective organization not only streamlines your workflow but also enhances overall well-being, fostering a balanced and fulfilling life where both professional and personal pursuits coexist.
        </div>
        <img src={organization} alt='learning' className={classes.img}></img>
      </div>
      <div className={classes.bottom}>
        <div className={classes.left}>
          <h1 className={classes.rotate}>Organization</h1>
        </div>
        <div className={classes.right}>
          <div className={classes.line1}>
            <div className={classes.box}>DISCIPLINE</div>
            <div className={classes.box}>TIME MANAGEMENT</div>
            <div className={classes.box}>PRIORITIZATION</div>
            <div className={classes.box}>PLANNING</div>
          </div>
          <div className={classes.line2}>
            <div className={classes.box}>SCHEDULING</div>
            <div className={classes.box}>PUNCTUALITY</div>
            <div className={classes.box}>STRUCTURING</div>
            <div className={classes.box}>GOALS</div>
          </div>
        </div>
      </div>
      <O />
    </div>
  );
}

export default Organization;