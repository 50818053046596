import React from 'react';
import classes from './Bloc.module.css';
import behaviour from './Images/behaviour.jpg';
import organization from './Images/organization.jpg';
import learning from './Images/learning.jpg';
import career from './Images/career.jpg';
import { useNavigate } from 'react-router-dom';

const arr = [
    { title: "Behaviour", img: behaviour, letter: "B" },
    { title: "Learning", img: learning, letter: "L" },
    { title: "Organization", img: organization, letter: "O" },
    { title: "Career", img: career, letter: "C" },
];

const Bloc = () => {
    const navigate = useNavigate();

    const handleNavigate = (title, source) => {
        navigate(`/${title}`, { state: { source } });
    };

    return (
        <div className={classes.container} id='bloc'>
            <div className={classes.box}>
                {arr.map((item, index) => {
                    return (
                        <div className={classes.innerContainer} key={index}>
                            <div className={classes.innerBox1} onClick={() => handleNavigate(item.title, 'innerBox1')}>{item.title}</div>
                            <img src={item.img} alt={item.title} style={{ backgroundColor: "white" }} onClick={() => handleNavigate(item.title, 'image')} />
                            <div className={classes.innerBox2} onClick={() => handleNavigate(item.title, 'innerBox2')}>{item.letter}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Bloc;
