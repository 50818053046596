import { useMediaQuery } from '@mui/material';
import React from 'react';

function BuildingBloc() {
    const isNonMobileScreens = useMediaQuery("(min-width:1001px)");
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: isNonMobileScreens ? "-6rem" : "" }}>
            <div style={{ backgroundColor: "#44546a", padding: isNonMobileScreens ? "1.5rem 2rem" : "1.5rem", color: "white", paddingRight: "2rem", width: isNonMobileScreens ? "90%" : "80%", borderRadius: "5px" }}>
                <h2 style={{ textAlign: "left", marginBottom: "0.5rem" }}>Building  BLOCs</h2>
                <p style={{ textAlign: "justify" }}>BLOCs stands for Behavioral, Learning, Organizational and Career related aspects of life. Well placed BLOCS help define you and how others perceive you. BLOCS in disarray leave you scattered and without focus, and can keep you from achieving your goals. That’s where we step in. There are tests to ascertain if you have any BLOCS are stacked right. These are time tested methods with proven efficacy used by mental health professionals across the globe.</p>
            </div>
            <div style={{ width: isNonMobileScreens ? "90%" : "80%", marginTop: "1.5rem" }}>
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <div style={{ backgroundColor: "#44546a", marginRight: isNonMobileScreens ? "2rem" : "0.8rem", padding: isNonMobileScreens ? "1.5rem 5rem" : "0.8rem 2rem", fontSize: "2.3rem", color: "white", fontFamily: "'Nunito', sans-serif", borderRadius: "5px" }}>B</div>
                    <div style={{ backgroundColor: "black", width: "80%", color: "white", textAlign: "left", fontSize: isNonMobileScreens ? "1.8rem" : "1.2rem", paddingTop: "1.5rem", paddingLeft: isNonMobileScreens ? "2rem" : "0.8rem", borderRadius: "5px" }}>Behavioral Patterns </div>
                </div>
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <div style={{ backgroundColor: "#44546a", marginRight: isNonMobileScreens ? "2rem" : "0.8rem", padding: isNonMobileScreens ? "1.5rem 5.15rem" : "0.8rem 2.1rem", fontSize: "2.3rem", color: "white", fontFamily: "'Nunito', sans-serif", borderRadius: "5px" }}>L</div>
                    <div style={{ backgroundColor: "black", width: "80%", color: "white", textAlign: "left", fontSize: isNonMobileScreens ? "1.8rem" : "1.2rem", paddingTop: "1.5rem", paddingLeft: isNonMobileScreens ? "2rem" : "0.8rem", borderRadius: "5px" }}>Learning Abilities </div>
                </div>
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <div style={{ backgroundColor: "#44546a", marginRight: isNonMobileScreens ? "2rem" : "0.8rem", padding: isNonMobileScreens ? "1.5rem 4.9rem" : "1.4rem 1.9rem", fontSize: "2.3rem", color: "white", fontFamily: "'Nunito', sans-serif", borderRadius: "5px" }}>O</div>
                    <div style={{ backgroundColor: "black", width: "80%", color: "white", textAlign: "left", fontSize: isNonMobileScreens ? "1.8rem" : "1.2rem", paddingTop: "1.5rem", paddingLeft: isNonMobileScreens ? "2rem" : "0.8rem", borderRadius: "5px" }}>Organizational Efficiencies </div>
                </div>
                <div style={{ display: "flex", marginBottom: "3rem" }}>
                    <div style={{ backgroundColor: "#44546a", marginRight: isNonMobileScreens ? "2rem" : "0.8rem", padding: isNonMobileScreens ? "1.5rem 5.08rem" : "2.1rem 1.9rem", fontSize: "2.3rem", color: "white", fontFamily: "'Nunito', sans-serif", borderRadius: "5px" }}>C</div>
                    <div style={{ backgroundColor: "black", width: "80%", color: "white", textAlign: "left", fontSize: isNonMobileScreens ? "1.8rem" : "1.2rem", paddingTop: "1.5rem", paddingLeft: isNonMobileScreens ? "2rem" : "0.8rem", borderRadius: "5px" }}>Charting your Course or Career Path  </div>
                </div>
            </div>
        </div>
    );
}

export default BuildingBloc;