import React from "react";
import classes from "./L.module.css";

function L() {
    return (
        <div className={classes.outer}>
            <div className={classes.wrapper}>
                <div className={classes.inner1}>L</div>
                <div className={classes.inner2}>
                    <p>Measuring Academic & Learning Abilities</p>
                </div>
            </div>

            <div className={classes.inner}>
                Different students learn differently. Learning is most successful when
                the mode of teaching corresponds to a student’s natural ability and
                interest.
                <br />
                <br />
                <br />
                Different students have different patterns of gathering information,
                experimentation, comprehension and different levels of critical thinking
                and the application of reasoning skills.
                <br />
                <br />
                <br />
                There are tests to objectively identify your natural preferences and
                abilities, so you know exactly how best you learn and what you need to
                concentrate on most.
            </div>
        </div>
    );
}

export default L;
