import React from "react";
import classes from "./C.module.css";

function C() {
    return (
        <div className={classes.outer}>
            <div className={classes.wrapper}>
                <div className={classes.inner1}>C</div>
                <div className={classes.inner2}>
                    <p>Identifying Career Choices</p>
                </div>
            </div>

            <div className={classes.inner}>
                The dynamic environment that we have created for ourselves, the changing
                technology, and the need to be different in the light of the competition
                around, requires students to be better informed about their interests
                and academic choices. And thus make better, informed decisions about
                their high school subjects and college majors.
                <br />
                <br />
                <br />
                A 4 year planning window gives time to identify strengths and address
                weaknesses and enable the student to pursue the career that fits best
                with their interests and abilities. Thus providing room for far more
                thought through choices and informed decisions on colleges and programs.
                <br />
                <br />
                <br />
                College students who have not given enough thought to their academic
                interests may need to change their majors midway through the program, in
                the process spend more money and time completing the program.
            </div>
        </div>
    );
}

export default C;
